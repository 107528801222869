import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useSplitConfigurationGroups} from '@/hooks';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {ErrorCode, isFormattedTrpcError} from '@zentact/common';
import {Button, SlideOverWithBrandedHeader, useNotification} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useMemo} from 'react';
import {DeepPartial, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {AddStoreForm, AddStoreFormData, getAddStoreFormSchema} from '../add-store/add-store-form';

type EditStorePanelProps = {
  isOpen: boolean;
  onClose: () => void;
  storeId: string;
  triggerRefetch: () => void;
  formDefaultValue?: DeepPartial<AddStoreFormData> | null;
};

export const EditStorePanel = ({
  isOpen,
  onClose,
  storeId,
  formDefaultValue,
  triggerRefetch,
}: EditStorePanelProps) => {
  const navigate = useNavigate();

  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {splitConfigurationGroups} = useSplitConfigurationGroups();

  const {data: store, isLoading: storeIsLoading} = trpc.store.getStoreById.useQuery(
    {storeId},
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      onError: error => {
        const errorCode = isFormattedTrpcError(error)
          ? error.data.errorCode
          : ErrorCode.ERROR_GENERIC;
        navigate(RoutePath.ERROR, {state: {errorCode}});
      },
    }
  );

  const defaultFormData = useMemo(
    () => ({
      displayName: store?.displayName,
      storeReferenceId: store?.storeReferenceId ?? '',
      businessAddress: {
        line1: store?.businessAddressLine1 ?? '',
        line2: store?.businessAddressLine2 ?? '',
        city: store?.businessAddressCity ?? '',
        state: store?.businessAddressState ?? '',
        postalCode: store?.businessAddressPostalCode ?? '',
      },
      businessPhoneNumber: store?.businessPhoneNumber ?? '',
      shopperStatement: store?.shopperStatement ?? '',
      transferInstrumentId: store?.transferInstrumentId,
      businessLineIds: store?.storeBusinessLines.map(sb => sb.businessLineId),
      splitConfigurationGroupId: store?.splitConfigurationGroupId ?? undefined,
    }),
    [store]
  );
  const form = useForm<AddStoreFormData>({
    resolver: zodResolver(getAddStoreFormSchema()),
    defaultValues: formDefaultValue ?? defaultFormData,
  });
  useEffect(() => {
    form.reset(formDefaultValue ?? defaultFormData);
  }, [formDefaultValue, defaultFormData]);

  const {handleSubmit, setError} = form;

  const editStoreMutation = trpc.store.editStore.useMutation({
    onSuccess: () => {
      showSuccessNotification(t`Store updated`, t`The store has been successfully updated.`);
      triggerRefetch();
      onClose();
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;

      if (errorCode === ErrorCode.STORE_NAME_IS_ALREADY_IN_USE) {
        setError('displayName', {
          type: 'manual',
          message: t`Store with the same name already exists`,
        });
        return;
      }

      if (errorCode === ErrorCode.STATE_IS_INVALID) {
        setError('businessAddress.state', {
          type: 'manual',
          message: t`State is Invalid`,
        });
        return;
      }

      if (errorCode === ErrorCode.POSTAL_CODE_IS_INVALID) {
        setError('businessAddress.postalCode', {
          type: 'manual',
          message: t`Postal Code is Invalid`,
        });
        return;
      }

      if (errorCode === ErrorCode.PHONE_NUMBER_IS_INVALID) {
        setError('businessPhoneNumber', {
          type: 'manual',
          message: t`Phone Number is Invalid`,
        });
        return;
      }

      showErrorNotification(
        t`Failed to create a store`,
        t`Something went wrong. Please try again later.`
      );
    },
  });

  const onSubmit = useCallback(
    (data: AddStoreFormData) => {
      if (!store) {
        return;
      }
      editStoreMutation.mutate({...data, storeId: store.id});
    },
    [store]
  );

  const onCancelClick = useCallback(
    (_event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onClose();
      form.reset();
    },
    [onClose]
  );
  if (!store || storeIsLoading) {
    return;
  }
  const {
    pspMerchantAccount: {countryCode},
  } = store;

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={t`Edit the store`}
      closeHandler={onCancelClick}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(onSubmit)}
              isLoading={editStoreMutation.isLoading}
            >
              <Trans>Edit Store</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onCancelClick}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <AddStoreForm
          form={form}
          splitConfigurationGroups={splitConfigurationGroups ?? []}
          countryCode={countryCode}
          businessLines={store.merchantAccount.businessLines}
          appliedBusinessLines={store.storeBusinessLines.map(line => line.businessLineId)}
          transferInstruments={store.merchantAccount.transferInstruments}
          editMode
        />
      </form>
    </SlideOverWithBrandedHeader>
  );
};
