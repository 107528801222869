import {trpc} from '@/api/trpcClient';
import {useSplitConfigurationGroups} from '@/hooks';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {ErrorCode, isFormattedTrpcError} from '@zentact/common';
import {Button, SlideOverWithBrandedHeader, useNotification} from '@zentact/ui-tailwind';
import {Dispatch, useCallback, useEffect} from 'react';
import {DeepPartial, useForm} from 'react-hook-form';
import {AddStoreForm, AddStoreFormData, getAddStoreFormSchema} from './add-store-form';

type AddStorePanelProps = {
  isOpen: boolean;
  onClose: Dispatch<void>;
  merchantAccount:
    | MerchantAccountDetailsOutput
    | MerchantAccountsListItem['merchantAccount']
    | null;
  merchantAccountId: string;
  triggerRefetch: () => void;
  addStoreFormDefaultValue?: DeepPartial<AddStoreFormData> | null;
};

export const AddStorePanel = ({
  isOpen,
  onClose,
  merchantAccount,
  merchantAccountId,
  addStoreFormDefaultValue,
  triggerRefetch,
}: AddStorePanelProps) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {splitConfigurationGroups, defaultSplitConfigurationGroup} = useSplitConfigurationGroups();

  const form = useForm<AddStoreFormData>({
    resolver: zodResolver(getAddStoreFormSchema()),
    defaultValues: addStoreFormDefaultValue ?? {
      splitConfigurationGroupId: defaultSplitConfigurationGroup?.id,
    },
  });
  const splitConfigurationGroupId = form.watch('splitConfigurationGroupId');

  useEffect(() => {
    if (splitConfigurationGroupId || !defaultSplitConfigurationGroup) {
      return;
    }

    form.setValue('splitConfigurationGroupId', defaultSplitConfigurationGroup.id);
  }, [splitConfigurationGroupId, defaultSplitConfigurationGroup]);

  useEffect(() => {
    form.reset({
      splitConfigurationGroupId: defaultSplitConfigurationGroup?.id,
    });
  }, [form, defaultSplitConfigurationGroup]);

  const {handleSubmit, setError} = form;

  const addStoreMutation = trpc.store.addNewStore.useMutation({
    onSuccess: () => {
      showSuccessNotification(t`Store created`, t`The store has been successfully created.`);
      triggerRefetch();
      onClose();
      form.reset();
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;

      if (errorCode === ErrorCode.STORE_NAME_IS_ALREADY_IN_USE) {
        setError('displayName', {
          type: 'manual',
          message: t`Store with the same name already exists`,
        });

        return;
      }

      if (errorCode === ErrorCode.STORE_REFERENCE_ID_ALREADY_EXISTS) {
        setError('storeReferenceId', {
          type: 'manual',
          message: t`Store with the same reference already exists`,
        });

        return;
      }

      if (errorCode === ErrorCode.STATE_IS_INVALID) {
        setError('businessAddress.state', {
          type: 'manual',
          message: t`State is Invalid`,
        });
        return;
      }

      if (errorCode === ErrorCode.POSTAL_CODE_IS_INVALID) {
        setError('businessAddress.postalCode', {
          type: 'manual',
          message: t`Postal Code is Invalid`,
        });
        return;
      }

      if (errorCode === ErrorCode.PHONE_NUMBER_IS_INVALID) {
        setError('businessPhoneNumber', {
          type: 'manual',
          message: t`Phone Number is Invalid`,
        });
        return;
      }

      showErrorNotification(
        t`Failed to create a store`,
        t`Something went wrong. Please try again later.`
      );
    },
  });

  const onSubmit = useCallback(
    (data: AddStoreFormData) => {
      addStoreMutation.mutate({...data, merchantAccountId});
    },
    [merchantAccountId]
  );

  const onCancelClick = useCallback(
    (_event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onClose();
      form.reset();
    },
    [onClose]
  );
  if (!merchantAccount) {
    return;
  }
  const {
    pspMerchantAccount: {countryCode},
  } = merchantAccount;

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={t`Add a new store`}
      closeHandler={onCancelClick}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(onSubmit)}
              isLoading={addStoreMutation.isLoading}
            >
              <Trans>Add Store</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onCancelClick}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <AddStoreForm
          form={form}
          splitConfigurationGroups={splitConfigurationGroups ?? []}
          countryCode={countryCode}
          businessLines={merchantAccount.businessLines}
          appliedBusinessLines={[]}
          transferInstruments={merchantAccount.transferInstruments}
        />
      </form>
    </SlideOverWithBrandedHeader>
  );
};
