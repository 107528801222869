import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useTenantIntervalFeesGroups} from '@/hooks';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {
  AlertOverlayWithConfirmation,
  Button,
  InputSearchSelect,
  Label,
  SlideOverWithBrandedHeader,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import {z} from 'zod';

const assignRecurringFeeGroupPanelSchema = () =>
  z.object({
    // biome-ignore lint/style/useNamingConvention: <explanation>
    tenantIntervalFeesGroupId: z.string({required_error: t`Recurring Fee Group is not selected`}),
  });

type AssignRecurringFeeGroupPanelData = z.infer<
  ReturnType<typeof assignRecurringFeeGroupPanelSchema>
>;

type AssignRecurringFeeGroupPanelProps = {
  merchantAccountRow: MerchantAccountsListItem | MerchantAccountDetailsOutput;
  onClose: () => void;
  isOpen: boolean;
  triggerRefetch: () => void;
};

export const AssignRecurringFeeGroupPanel = ({
  merchantAccountRow,
  onClose,
  isOpen,
  triggerRefetch,
}: AssignRecurringFeeGroupPanelProps) => {
  const {i18n} = useLingui();
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);

  const merchantAccount =
    // @ts-ignore fix TS2589: Type instantiation is excessively deep and possibly infinite.
    'merchantAccount' in merchantAccountRow
      ? merchantAccountRow.merchantAccount
      : merchantAccountRow;

  const {
    id: merchantAccountId,
    businessName,
    tenantIntervalFeesGroup: currentTenantIntervalFeesGroup,
  } = merchantAccount;

  const {tenantIntervalFeesGroups} = useTenantIntervalFeesGroups();

  const updateMerchantEmailNotificationsForm = useForm<AssignRecurringFeeGroupPanelData>({
    resolver: zodResolver(assignRecurringFeeGroupPanelSchema()),
    defaultValues: {
      tenantIntervalFeesGroupId: currentTenantIntervalFeesGroup?.id ?? undefined,
    },
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: {errors},
  } = updateMerchantEmailNotificationsForm;
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const assignTenantIntervalFeeGroupToMerchant =
    trpc.merchantAccount.assignTenantIntervalFeeGroupToMerchant.useMutation({
      onSuccess: () => {
        triggerRefetch();
        onClose();
        showSuccessNotification(
          t`Merchant recurring fee group changed`,
          t`You have successfully assigned a new receurring fee group to the merchant.`
        );
      },
      onError: error => {
        showErrorNotification(t`Error`, error.message);
      },
    });

  const onSubmit = useCallback(
    (data: AssignRecurringFeeGroupPanelData) => {
      assignTenantIntervalFeeGroupToMerchant.mutate({
        merchantAccountId,
        tenantIntervalFeesGroupId: data.tenantIntervalFeesGroupId,
      });
    },
    [merchantAccountId]
  );

  const selectedTenantIntervalFeesGroupId = watch('tenantIntervalFeesGroupId');
  const selectedGroup = tenantIntervalFeesGroups.find(
    group => group.id === selectedTenantIntervalFeesGroupId
  );

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._("Update Store's Recurring Fee Group")}
      text={i18n._('This setting lets you change the recurring fees for the merchant account')}
      closeHandler={onClose}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={() => setConfirmationOpen(true)}
              isLoading={assignTenantIntervalFeeGroupToMerchant.isLoading}
              disabled={currentTenantIntervalFeesGroup?.id === selectedTenantIntervalFeesGroupId}
            >
              <Trans>Update Fee Group</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="flex flex-col gap-3">
            <Label
              text={
                <div className="flex justify-between">
                  <span>
                    <Trans>Recurring Fee Group</Trans>
                  </span>
                  <Link
                    to={RoutePath.RECURRING_FEE_GROUPS}
                    target="_blank"
                    className="text-primary-600"
                  >
                    <Trans>View Fee Groups</Trans>
                  </Link>
                </div>
              }
              className="mt-8 mb-10"
            >
              <InputSearchSelect
                className="mb-2"
                value={selectedTenantIntervalFeesGroupId}
                onChange={val =>
                  setValue(
                    'tenantIntervalFeesGroupId',
                    Array.isArray(val) ? (val[val.length - 1] as string) : val
                  )
                }
                options={tenantIntervalFeesGroups.map(group => ({id: group.id, label: group.name}))}
                placeholder={t`Please select a fee group`}
              />
              <ValidationError isVisible={Boolean(errors.tenantIntervalFeesGroupId)}>
                {errors.tenantIntervalFeesGroupId?.message}
              </ValidationError>
            </Label>
          </div>
        </div>
        <AlertOverlayWithConfirmation
          open={isConfirmationOpen}
          setOpen={setConfirmationOpen}
          handleAction={handleSubmit(onSubmit)}
          localeText={{
            title: t`Confirm Fee Group Change`,
            description: (
              <div>
                <Trans>
                  Are you sure you want to change the recurring fee group of{' '}
                  <span className="font-semibold">{businessName}</span> to{' '}
                  <span className="font-semibold">{selectedGroup?.name}</span>? <br />
                  <br />
                  <span className="font-semibold">
                    Please confirm, as you are responsible for communicating price changes to your
                    merchants.
                  </span>
                </Trans>
              </div>
            ),
            confirm: t`Confirm`,
            cancel: t`Cancel`,
          }}
          mode="warning"
          buttonMode="success"
          loading={assignTenantIntervalFeeGroupToMerchant.isLoading}
        />
      </form>
    </SlideOverWithBrandedHeader>
  );
};
