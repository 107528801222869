import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useSplitConfigurationGroups, useTenantIntervalFeesGroups} from '@/hooks';
import {useStore} from '@/store';
import {useAuth0} from '@auth0/auth0-react';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {ErrorCode, MerchantAccountPublicStatus, isFormattedTrpcError} from '@zentact/common';
import {
  AlertOverlayWithConfirmation,
  Button,
  CancelOnboardingConfirmation,
  DropDownMinimalMenuIcon,
  SlideOverWithBrandedHeader,
  useNotification,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useState} from 'react';
import {DeepPartial, useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import * as z from 'zod';
import {AddStorePanel} from '../add-store';
import {AddStoreFormData} from '../add-store/add-store-form';
import {AssignOrganizationDialog} from '../assign-organization-dialog';
import {
  OrganizationForm,
  OrganizationFormData,
  getOrganizationFormSchema,
} from '../organization-form/organization-form';
import {MerchantReviewPanelProps} from './merchant-review-panel';

type StableProps = {
  businessName: string;
  registrationSessionId: string;
  triggerRefetch: () => void;
  openReviewPanel?: (row: MerchantReviewPanelProps['merchantAccountRow']) => void;
  openAssignRecurringFeeGroupPanel?: (
    row: MerchantAccountDetailsOutput | MerchantAccountsListItem | null
  ) => void;
  viewType: 'dots-button' | 'chevron-button';
};

type Props = StableProps &
  (
    | {
        merchantAccountRow: MerchantAccountsListItem;
        openDetailsPanel: ((row: MerchantAccountsListItem) => void) | null;
      }
    | {
        merchantAccountRow: MerchantAccountDetailsOutput;
      }
  );

const statusToItemsMap = (
  action: (
    action:
      | 'close'
      | 'resend'
      | 'revoke'
      | 'editAndResend'
      | 'review'
      | 'details'
      | 'cancelOnboarding'
      | 'archive'
      | 'assignFeeGroup'
      | 'openOnboarding'
      | 'assignOrganization'
      | 'addNewStore'
  ) => void,
  {
    includeViewDetails,
    includeReviewOption,
    multiStoreFeatureEnabled,
  }: {
    includeViewDetails?: boolean;
    includeReviewOption?: boolean;
    managedRiskEnabled?: boolean;
    multiStoreFeatureEnabled: boolean;
  }
): Record<
  MerchantAccountPublicStatus,
  {name: string; onClick: () => void; itemClassName?: string}[]
> => {
  const details = includeViewDetails ? [{name: t`Details`, onClick: () => action('details')}] : [];
  const assignFeeGroup = [
    {name: t`Assign Recurring Fee Group`, onClick: () => action('assignFeeGroup')},
  ];
  return {
    [MerchantAccountPublicStatus.ACTIVE]: [
      ...details,
      ...assignFeeGroup,
      ...(multiStoreFeatureEnabled
        ? [
            {
              name: t`Add New Store`,
              onClick: () => action('addNewStore'),
            },
          ]
        : []),
      {
        name: t`Close Permanently`,
        onClick: () => action('close'),
        itemClassName: 'text-red-500',
      },
    ],
    [MerchantAccountPublicStatus.REJECTED]: [
      ...details,
      {name: t`Resend`, onClick: () => action('resend')},
      {name: t`Open Hosted Onboarding`, onClick: () => action('openOnboarding')},
      {name: t`Archive`, onClick: () => action('archive'), itemClassName: 'text-red-500'},
    ],
    [MerchantAccountPublicStatus.APPLICATION_IN_PROGRESS]: [
      ...details,
      ...assignFeeGroup,
      {name: t`Resend`, onClick: () => action('resend')},
      {name: t`Open Hosted Onboarding`, onClick: () => action('openOnboarding')},
      {
        name: t`Cancel Onboarding`,
        onClick: () => action('cancelOnboarding'),
        itemClassName: 'text-red-500',
      },
    ],
    [MerchantAccountPublicStatus.INVITE_ACCEPTED]: [
      ...details,
      ...assignFeeGroup,
      {name: t`Resend`, onClick: () => action('resend')},
      {
        name: t`Cancel Onboarding`,
        onClick: () => action('cancelOnboarding'),
        itemClassName: 'text-red-500',
      },
    ],
    [MerchantAccountPublicStatus.DEACTIVATED]: [
      ...details,
      {name: t`Archive`, onClick: () => action('archive'), itemClassName: 'text-red-500'},
    ],
    [MerchantAccountPublicStatus.CLOSING]: details,
    [MerchantAccountPublicStatus.INVITED]: [
      ...details,
      ...assignFeeGroup,
      {name: t`Edit & Resend`, onClick: () => action('editAndResend')},
      {
        name: t`Revoke Invitation`,
        onClick: () => action('revoke'),
        itemClassName: 'text-red-500',
      },
    ],
    [MerchantAccountPublicStatus.INITIATED]: [
      ...details,
      ...assignFeeGroup,
      {name: t`Edit & Invite`, onClick: () => action('editAndResend')},
      {
        name: t`Revoke`,
        onClick: () => action('revoke'),
        itemClassName: 'text-red-500',
      },
    ],
    [MerchantAccountPublicStatus.INVITE_EXPIRED]: [
      ...details,
      {name: t`Edit & Resend`, onClick: () => action('editAndResend')},
      {name: t`Archive`, onClick: () => action('archive'), itemClassName: 'text-red-500'},
    ],
    [MerchantAccountPublicStatus.INVITE_REVOKED]: [
      ...details,
      {name: t`Edit & Resend`, onClick: () => action('editAndResend')},
      {name: t`Archive`, onClick: () => action('archive'), itemClassName: 'text-red-500'},
    ],
    [MerchantAccountPublicStatus.APPLICATION_REVOKED]: [
      ...details,
      {name: t`Resend Invitation`, onClick: () => action('resend')},
      {name: t`Archive`, onClick: () => action('archive'), itemClassName: 'text-red-500'},
    ],
    [MerchantAccountPublicStatus.APPLICATION_IN_REVIEW]: [
      ...details,
      ...assignFeeGroup,
      ...(includeReviewOption ? [{name: t`Review`, onClick: () => action('review')}] : []),
    ],
    [MerchantAccountPublicStatus.REVIEW_REJECTED]: [
      ...details,
      ...(includeReviewOption ? [{name: t`Review again`, onClick: () => action('review')}] : []),
      {name: t`Archive`, onClick: () => action('archive'), itemClassName: 'text-red-500'},
    ],
    [MerchantAccountPublicStatus.NEEDS_ORGANIZATION]: [
      ...details,
      {
        name: t`Assign Organization`,
        onClick: () => action('assignOrganization'),
        itemClassName: 'text-red-500',
      },
    ],
    [MerchantAccountPublicStatus.ASSIGNING_ORGANIZATION]: details,
    [MerchantAccountPublicStatus.ADDITIONAL_DATA_REQUIRED]: [
      ...details,
      {name: t`Open Hosted Onboarding`, onClick: () => action('openOnboarding')},
    ],
  };
};

const merchantActionsParamsSchema = z.object({
  addStorePanelOpenForMerchantAccountId: z.string().optional(),
});

export const MerchantActions = (props: Props) => {
  const {
    merchantAccountRow,
    registrationSessionId,
    businessName,
    triggerRefetch,
    openReviewPanel,
    viewType,
  } = props;
  const {user} = useAuth0();
  const {tenant} = useStore();
  const location = useLocation();
  const [resendInviteSlideOutOpen, setResendInviteSlideOutOpen] = useState(false);
  const [deactivateConfirmationOpen, setDeactivateConfirmationOpen] = useState(false);
  const [cancelOnboardingConfirmationOpen, setCancelOnboardingConfirmationOpen] = useState(false);
  const [archiveConfirmationOpen, setArchiveConfirmationOpen] = useState(false);
  const [assignOrganizationOpen, setAssignOrganization] = useState(false);

  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {splitConfigurationGroups} = useSplitConfigurationGroups();
  const {tenantIntervalFeesGroups} = useTenantIntervalFeesGroups();

  const navigate = useNavigate();

  const addStoreFormData: DeepPartial<AddStoreFormData> = location?.state?.addStoreFormData;

  const {
    typedSearchParams: {addStorePanelOpenForMerchantAccountId},
    setTypedSearchParams,
  } = useTypedSearchParams(merchantActionsParamsSchema);

  const resendForm = useForm<OrganizationFormData>({
    resolver: zodResolver(getOrganizationFormSchema(tenant.features?.merchantReferenceIdRequired)),
  });
  const {handleSubmit, setError} = resendForm;

  const merchantAccountId =
    'merchantAccount' in merchantAccountRow
      ? merchantAccountRow.merchantAccount?.id
      : merchantAccountRow.id;
  const merchantAccount =
    // @ts-ignore fix infinite deep error
    'merchantAccount' in merchantAccountRow
      ? merchantAccountRow.merchantAccount
      : merchantAccountRow;

  const multiStoreFeatureEnabled = !!tenant.features?.enableMultiStore;

  const getInvitationDetail = trpc.merchantRegistration.getInvitationDetails.useQuery(
    {
      registrationSessionId,
    },
    {enabled: resendInviteSlideOutOpen}
  );
  const revokeMutation = trpc.merchantRegistration.revokeInvitation.useMutation();
  const closeMerchantMutation = trpc.merchantAccount.closeMerchantAccount.useMutation({
    onSuccess: () => {
      triggerRefetch();
      setDeactivateConfirmationOpen(false);
      showSuccessNotification(
        t`Merchant account closed`,
        t`You have successfully closed ${businessName}.`
      );
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;
      if (errorCode === ErrorCode.BALANCE_ACCOUNT_HAS_BALANCE) {
        showErrorNotification(
          t`Some stores has non zero balance`,
          t`Please wait until the balance is be paid out to the bank account or transfer it manualy from the store page before trying again.`
        );
        setDeactivateConfirmationOpen(false);
        return;
      }
      showErrorNotification(t`Something went wrong. Please try again later.`);
    },
  });

  const editAndResendMutation = trpc.merchantRegistration.editAndResendInvitation.useMutation({
    onSuccess: (_res, {businessName}) => {
      setResendInviteSlideOutOpen(false);
      triggerRefetch();
      showSuccessNotification(
        t`Invitation resent!`,
        t`You have successfully resent the invitation to ${businessName}.`
      );
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;
      if (errorCode === ErrorCode.MERCHANT_REFERENCE_ID_ALREADY_EXISTS) {
        setError('merchantReferenceId', {
          type: 'manual',
          message: t`Merchant Reference ID already exists`,
        });

        return;
      }
      if (errorCode === ErrorCode.MERCHANT_REGISTRATION_ALREADY_EXISTS) {
        setError('merchantName', {
          type: 'manual',
          message: t`Merchant with the same name already exists`,
        });

        return;
      }
      setResendInviteSlideOutOpen(false);
      showErrorNotification(t`Error`, error.message);
    },
  });

  const resendMutation = trpc.merchantRegistration.resendInvitation.useMutation({
    onSuccess: () => {
      setResendInviteSlideOutOpen(false);
      triggerRefetch();
      showSuccessNotification(
        t`Invitation sent!`,
        t`You have successfully sent the invitation to ${businessName}.`
      );
    },
    onError: error => {
      setResendInviteSlideOutOpen(false);
      showErrorNotification(t`Error`, error.message);
    },
  });

  const archiveMutation = trpc.merchantAccount.archiveMerchantAccount.useMutation({
    onSuccess: () => {
      triggerRefetch();
      setArchiveConfirmationOpen(false);
      navigate(RoutePath.CUSTOMERS_MERCHANTS);
      showSuccessNotification(
        t`Merchant archived`,
        t`You have successfully archived ${businessName}.`
      );
    },
  });

  const trpcContext = trpc.useUtils();

  const handleOpenHop = useCallback(async () => {
    try {
      const {redirectUrl} = await trpcContext.merchantRegistration.getResumeHopLink.fetch({
        merchantAccountId,
      });
      window.location.href = redirectUrl;
    } catch (e) {
      showErrorNotification(t`Failed to generate a hop link`, (e as Error).message);
    }
  }, [registrationSessionId, showErrorNotification]);

  const handleAction = (action: string) => {
    if (action === 'details' && 'openDetailsPanel' in props && props.openDetailsPanel) {
      props.openDetailsPanel(props.merchantAccountRow);
    }
    if (action === 'editAndResend') {
      setResendInviteSlideOutOpen(true);
    }
    if (action === 'revoke') {
      revokeMutation.mutate(
        {registrationSessionId},
        {
          onSuccess: () => {
            triggerRefetch();
            showSuccessNotification(
              t`Invitation revoked!`,
              t`You have successfully revoked the invitation.`
            );
          },
        }
      );
    }
    if (action === 'cancelOnboarding') {
      setCancelOnboardingConfirmationOpen(true);
    }
    if (action === 'close') {
      setDeactivateConfirmationOpen(true);
    }
    if (action === 'resend') {
      resendMutation.mutate({
        registrationSessionId,
        inviterName: user?.name || user?.email || 'Unknown',
      });
    }
    if (action === 'review' && openReviewPanel) {
      openReviewPanel(merchantAccountRow);
    }
    if (action === 'archive') {
      setArchiveConfirmationOpen(true);
    }
    if (action === 'assignFeeGroup' && props.openAssignRecurringFeeGroupPanel) {
      props.openAssignRecurringFeeGroupPanel(merchantAccountRow);
    }
    if (action === 'openOnboarding') {
      handleOpenHop();
    }
    if (action === 'assignOrganization') {
      setAssignOrganization(true);
    }
    if (action === 'addNewStore') {
      setTypedSearchParams({addStorePanelOpenForMerchantAccountId: merchantAccountId});
    }
  };

  useEffect(() => {
    if (!getInvitationDetail.data) {
      return;
    }

    resendForm.reset({
      organization: {
        organizationType: 'new',
        organizationName: getInvitationDetail.data.organizationName,
      },
      merchantName: getInvitationDetail.data.merchantName ?? undefined,
      merchantEmail: getInvitationDetail.data.merchantEmail,
      merchantReferenceId: getInvitationDetail.data.merchantReferenceId ?? undefined,
      splitConfigurationGroupId: getInvitationDetail.data.splitConfigurationGroupId ?? undefined,
    });
  }, [getInvitationDetail.data]);

  const handleClose = useCallback(() => {
    closeMerchantMutation.mutate({merchantAccountId});
  }, [merchantAccountId]);

  const handleEditAndResend = useCallback(
    (data: OrganizationFormData) => {
      editAndResendMutation.mutate({
        inviterName: user?.name || user?.email || 'Unknown',
        registrationSessionId,
        businessName: data.merchantName,
        email: data.merchantEmail,
        merchantReferenceId: data.merchantReferenceId,
        splitConfigurationGroupId: data.splitConfigurationGroupId,
      });
    },
    [user, registrationSessionId]
  );

  const handleArchived = useCallback(() => {
    if (merchantAccountId) {
      archiveMutation.mutate({merchantAccountId});
    }
  }, [merchantAccountId]);

  const menuItems = statusToItemsMap(handleAction, {
    includeViewDetails: 'openDetailsPanel' in props && !!props.openDetailsPanel,
    includeReviewOption: !!props.openReviewPanel,
    multiStoreFeatureEnabled,
  })[merchantAccountRow.status];
  if (!menuItems) return null;

  return (
    <>
      <DropDownMinimalMenuIcon
        items={menuItems}
        buttonContent={viewType === 'chevron-button' ? <Trans>Actions</Trans> : undefined}
      />
      {deactivateConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={deactivateConfirmationOpen}
          setOpen={setDeactivateConfirmationOpen}
          handleAction={handleClose}
          loading={closeMerchantMutation.isLoading}
          localeText={{
            title: t`Close merchant`,
            description: t`Are you sure you want to permanently close ${businessName}?`,
            confirm: t`Close Merchant Account`,
            cancel: t`Cancel`,
          }}
        />
      )}
      {cancelOnboardingConfirmationOpen && (
        <CancelOnboardingConfirmation
          isOpen={cancelOnboardingConfirmationOpen}
          setOpen={setCancelOnboardingConfirmationOpen}
          refetch={triggerRefetch}
          trpc={trpc}
          businessName={businessName}
          registrationSessionId={registrationSessionId}
        />
      )}
      {archiveConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={archiveConfirmationOpen}
          setOpen={setArchiveConfirmationOpen}
          handleAction={handleArchived}
          localeText={{
            title: t`Archive merchant`,
            description: t`Are you sure you want to archive ${businessName}? This action cannot be undone. You will still be able to create a new merchant account with the same business name.`,
            confirm: t`Archive`,
            cancel: t`Cancel`,
          }}
        />
      )}
      {assignOrganizationOpen && (
        <AssignOrganizationDialog
          isOpen={assignOrganizationOpen}
          setOpen={setAssignOrganization}
          merchantAccountId={merchantAccountId}
          businessName={businessName}
          triggerRefetch={triggerRefetch}
        />
      )}
      {addStorePanelOpenForMerchantAccountId === merchantAccountId && (
        <AddStorePanel
          isOpen={!!addStorePanelOpenForMerchantAccountId}
          onClose={() => setTypedSearchParams({addStorePanelOpenForMerchantAccountId: undefined})}
          triggerRefetch={triggerRefetch}
          merchantAccountId={merchantAccountId}
          merchantAccount={merchantAccount}
          addStoreFormDefaultValue={addStoreFormData}
        />
      )}
      <SlideOverWithBrandedHeader
        isOpen={resendInviteSlideOutOpen && Boolean(getInvitationDetail.isFetched)}
        title={
          merchantAccountRow.status === MerchantAccountPublicStatus.INITIATED
            ? t`Edit and Invite`
            : t`Edit and Resend Invitation`
        }
        text={
          merchantAccountRow.status === MerchantAccountPublicStatus.INITIATED
            ? t`Edit the invitation and send it to the organization.`
            : t`Edit the invitation and resend it to the organization.`
        }
        closeHandler={() => {
          setResendInviteSlideOutOpen(false);
        }}
        footer={
          <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
            <div className="flex shrink-0 gap-x-3">
              <Button
                variant="primary"
                size="lg"
                className="w-fit"
                onClick={handleSubmit(handleEditAndResend)}
                isLoading={editAndResendMutation.isLoading}
              >
                <Trans>Send Invite</Trans>
              </Button>
            </div>
            <Button
              variant="secondary"
              size="lg"
              className="w-fit"
              onClick={() => setResendInviteSlideOutOpen(false)}
            >
              <Trans>Close</Trans>
            </Button>
          </footer>
        }
      >
        <form onSubmit={handleSubmit(handleEditAndResend)}>
          <OrganizationForm
            form={resendForm}
            organizationReadOnly
            splitConfigurationGroups={splitConfigurationGroups}
            tenantIntervalFeesGroups={tenantIntervalFeesGroups}
            merchantReferenceIdRequired={tenant.features?.merchantReferenceIdRequired}
          />
        </form>
      </SlideOverWithBrandedHeader>
    </>
  );
};
