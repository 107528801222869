import {I18n} from '@lingui/core';
import {t} from '@lingui/macro';
import {
  OrganizationAccountActivityDetailsOutput,
  OrganizationAccountActivitySummaryOutput,
  PayoutsSummaryOutput,
} from '@zentact/api/src/trpc/routers/financeRouter';
import {MerchantAccountsListOutput} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {OrganizationListOutput} from '@zentact/api/src/trpc/routers/organizationRouter';
import {StoresListOutput} from '@zentact/api/src/trpc/routers/storeRouter';
import {
  CSV_EXPORT_FULL_DATE_FORMAT,
  CurrencyCode,
  LocaleCode,
  MerchantAccountPublicStatus,
  allIndustryGroups,
  formatAccountingWithoutCurrency,
  formatAmountWithoutCurrency,
  formatTransferCategory,
} from '@zentact/common';
import {ExportToCsv, getLocalizedStatusMap, getStoreLocalizedStatusMap} from '@zentact/ui-tailwind';
import {formatDescription} from '@zentact/ui-tailwind/layout/finance/account-activity-details/columns';
import {getPayoutDateTime} from '@zentact/ui-tailwind/layout/finance/payoutDateFormat';
import {DateTime} from 'luxon';

export const exportToCsvMerchantsAwaitingApproval = (
  fullMerchantsWaitingApprovalList: MerchantAccountsListOutput,
  i18n: I18n
) => {
  const exporter = new ExportToCsv({
    filename: `merchants-awaiting-approval-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!fullMerchantsWaitingApprovalList.rows.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    fullMerchantsWaitingApprovalList.rows.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`PSP Merchant Account`]: row.merchantAccount.pspMerchantAccountName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Merchant Account`]: row.merchantAccount.businessName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Organization`]: row.organization?.name || i18n._('N/A'),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Legal Business Name`]: row.legalBusinessName || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Doing Bussiness As`]: row.doingBusinessAs || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Country`]: row.businessAddressCountry || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Address`]: [
        row.businessAddressCity,
        row.businessAddressLine1,
        row.businessAddressLine2,
        row.businessAddressPostalCode,
      ]
        .filter(v => !!v)
        .join(' '),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Signatory`]: `${row.firstName} ${row.lastName}`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Contact Email`]: row.inviteeEmail || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`KYC Completed On`]: DateTime.fromISO(row.kycCompletedAt as string).toFormat(
        CSV_EXPORT_FULL_DATE_FORMAT
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Created Date`]: DateTime.fromISO(row.createdAt).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`IP Address`]: row.ipAddress || t`N/A`,
    }))
  );
};

export const exportToCsvMerchants = (fullMerchantList: MerchantAccountsListOutput, i18n: I18n) => {
  const exporter = new ExportToCsv({
    filename: `tenant-organization-merchants-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!fullMerchantList.rows.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    fullMerchantList.rows.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`PSP Merchant Account`]: row.merchantAccount.pspMerchantAccountName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Merchant Account Reference Id`]: row.merchantAccount.tenantMerchantReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Store ID`]: row.merchantAccount.primaryStore?.id || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Store Reference ID`]: row.merchantAccount.primaryStore?.storeReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Adyen Balance Account ID`]: row.merchantAccount.primaryStore?.balanceAccountId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Adyen Account Holder ID`]: row.accountHolderId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Merchant Account`]: row.merchantAccount.businessName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Legal Business Name`]: row.legalBusinessName || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Organization`]: row.organization?.name || i18n._('N/A'),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Organization Reference ID`]: row.organization?.referenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Contact Email`]: row.inviteeEmail || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Status`]: getLocalizedStatusMap(i18n)[row.status as MerchantAccountPublicStatus],
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Account Time Zone`]: row.timeZone || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Transaction Fee Group`]:
        row.merchantAccount?.primaryStore?.splitConfigurationGroup?.name || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Industry`]:
        (allIndustryGroups[row.businessMccCode as keyof typeof allIndustryGroups] ||
          t`Unrecognized`) + (row.businessMccCode ? ` (${row.businessMccCode})` : ''),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Onboarded At`]: row.merchantAccount.onboardedAt
        ? DateTime.fromISO(row.merchantAccount.onboardedAt as string).toFormat(
            CSV_EXPORT_FULL_DATE_FORMAT
          )
        : t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Last Updated At`]: DateTime.fromISO(row.updatedAt).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
    }))
  );
};

export const exportToCsvOrganizations = (fullMerchantList: OrganizationListOutput, i18n: I18n) => {
  const exporter = new ExportToCsv({
    filename: `organizations-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!fullMerchantList.rows.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    fullMerchantList.rows.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`PSP Merchant Account`]: row.pspMerchantAccountName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Organization`]: row.name,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Reference ID`]: row.referenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Active Merchants`]: row.activeMerchantAccountsCount,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Onboarding Merchants`]: row.onboardingMerchantAccountsCount,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Merchants with Onboarding Errors`]: row.onboardingErrorsMerchantsCount,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Status`]: row.status,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Status`]: getLocalizedStatusMap(i18n)[row.status as MerchantAccountPublicStatus],
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Last Active`]: DateTime.fromISO(row.updatedAt).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Support Email`]: row.supportEmail || t`N/A`,
    }))
  );
};

export const exportToCsvOrganizationsPayouts = (
  fullPayoutsList: PayoutsSummaryOutput,
  i18n: I18n
) => {
  const exporter = new ExportToCsv({
    filename: `organization-payouts-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!fullPayoutsList.rows.length) {
    throw new Error(t`No data to export`);
  }
  exporter.generateCsv(
    fullPayoutsList.rows.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Transfer Date`]: DateTime.fromISO(row.valueDate).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Merchant Account Reference ID`]: row.tenantMerchantReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Store ID`]: row.storeId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Store Reference ID`]: row.storeReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`PSP Merchant Account`]: row.pspMerchantAccountName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Merchant Account`]: row.merchantAccountName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Organization`]: row.organizationName || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Organization Reference ID`]: row.organizationReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Currency`]: row.currency,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Deposit Amount`]: formatAmountWithoutCurrency(
        row.amount,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Reference`]: row.reference,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Bank Account`]: row.bankAccountLast4,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Account Type`]: row.bankAccountType,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Status`]: row.status,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Estimated Arrival Time`]: row.estimatedArrivalTime
        ? DateTime.fromISO(row.estimatedArrivalTime).toFormat(CSV_EXPORT_FULL_DATE_FORMAT)
        : '',
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Batch Id`]: row.batchId || i18n._('N/A'),
    }))
  );
};

export const exportToCsvOrganizationsAccountActivityDetails = (
  accountActivityDetails: OrganizationAccountActivityDetailsOutput['rows'],
  i18n: I18n
) => {
  const exporter = new ExportToCsv({
    filename: `organizations-account-activity-details-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!accountActivityDetails.length) {
    throw new Error(t`No data to export`);
  }
  exporter.generateCsv(
    accountActivityDetails.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`PSP Merchant Account`]: row.pspMerchantAccountName,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Transaction Date`]: DateTime.fromISO(row.creationDate).toFormat(
        CSV_EXPORT_FULL_DATE_FORMAT
      ),
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Value Date`]: getPayoutDateTime(row.valueDate).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Merchant Account`]: row.merchantAccount.businessName,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Organization`]: row.organization?.name || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Organization Reference ID`]: row.organization?.referenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Merchant Reference`]: row.paymentMerchantReference || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Description`]:
        formatDescription(row.description, i18n, 'merchantAccount' in row) || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Currency`]: row.currency,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Amount`]: formatAmountWithoutCurrency(
        row.amount * (row.direction === 'outgoing' ? -1 : 1),
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Category`]: formatTransferCategory(row),
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Type`]:
        row.type === 'internalTransfer'
          ? i18n._('Internal Transfer')
          : row.type[0]?.toUpperCase() + row.type.slice(1), // Capitalize type
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Transaction Id`]: row.pspPaymentReference || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Batch Id`]: row.batchId || i18n._('N/A'),
    }))
  );
};

export const exportToCsvOrganizationsAccountActivitySummary = (
  accountActivitySummary: OrganizationAccountActivitySummaryOutput,
  i18n: I18n
) => {
  const exporter = new ExportToCsv({
    filename: `organizations-account-activity-summary-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!accountActivitySummary.rows.length) {
    throw new Error(t`No data to export`);
  }
  exporter.generateCsv(
    accountActivitySummary.rows.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Payout Date`]: DateTime.fromISO(row.valueDay).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Capture Date`]: DateTime.fromISO(row.transactionDay).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Merchant Acount`]: row.merchantAccountName,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Merchant Acount Id`]: row.merchantAccountId,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Organization`]: row.organizationName,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Organization Id`]: row.organizationId,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Organization Reference ID`]: row.organizationReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Currency`]: row.currency,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Credits`]: formatAmountWithoutCurrency(
        row.creditsAmount,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Number of Credits`]: row.creditsCount,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Debits`]: formatAccountingWithoutCurrency(
        -row.debitsAmount,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Number of Debits`]: row.debitsCount,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Net`]: formatAccountingWithoutCurrency(
        row.creditsAmount - row.debitsAmount,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
    }))
  );
};

export const exportToCsvStores = (fullStoresList: StoresListOutput, i18n: I18n) => {
  const exporter = new ExportToCsv({
    filename: `tenant-stores-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!fullStoresList.rows.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    fullStoresList.rows.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`PSP Merchant Account`]: row.pspMerchantAccountName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Merchant Account`]: row.merchantAccount.businessName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Merchant Account ID`]: row.merchantAccount.id,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Merchant Account Reference ID`]: row.merchantAccount.tenantMerchantReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Account Holder ID`]: row.merchantAccount.accountHolderId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Store ID`]: row.id,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Store Reference ID`]: row.storeReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Store Name`]: row.displayName || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Organization`]: row.organization?.name || i18n._('N/A'),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Organization ID`]: row.organization?.id || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Status`]: getStoreLocalizedStatusMap(i18n)[row.status],
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Transaction Fee Group`]: row.splitConfigurationGroup?.name || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Transaction Fee Group ID`]: row.splitConfigurationGroup?.id || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Split Configuration Group ID`]:
        row.splitConfigurationGroup?.adyenSplitConfigurationId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Last Updated At`]: DateTime.fromISO(row.updatedAt).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
    }))
  );
};
