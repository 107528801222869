import {I18n} from '@lingui/core';
import {useLingui} from '@lingui/react';
import {StoreDetailsOutput} from '@zentact/api/src/trpc/routers/storeRouter';
import {formatLocaleDate} from '@zentact/common';
import {StoreEventType} from '@zentact/db';
import {Link} from 'react-router-dom';

type Params = {
  i18n: I18n;
  storeEvent: StoreDetailsOutput['storeEvents'][0];
  transactionFeeGroupListPath?: string;
};

export const renderStoreEventType = ({i18n, storeEvent, transactionFeeGroupListPath}: Params) => {
  if (storeEvent.type === 'TRANSACTION_FEE_GROUP_UPDATED') {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>
          {i18n._('Transaction fees group updated')}{' '}
          {storeEvent.oldFeeGroup.id && storeEvent.oldFeeGroup.name && (
            <>
              {i18n._('from')}{' '}
              <Link
                className="text-primary-600"
                to={`${transactionFeeGroupListPath}/${storeEvent.oldFeeGroup.id}`}
              >
                {storeEvent.oldFeeGroup.name}
              </Link>{' '}
            </>
          )}
          {i18n._('to')}{' '}
          <Link
            className="text-primary-600"
            to={`${transactionFeeGroupListPath}/${storeEvent.newFeeGroup.id}`}
          >
            {storeEvent.newFeeGroup.name}
          </Link>{' '}
          {i18n._('by {email}', {email: storeEvent.authUser.email})}
        </span>
      </div>
    );
  }

  if (storeEvent.type === 'ADDRESS_UPDATED') {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>
          {i18n._('Address updated')}{' '}
          {i18n._('from "{previousValue}" to "{nextValue}" by {email}', {
            previousValue: storeEvent.oldAddress,
            nextValue: storeEvent.newAddress,
            email: storeEvent.authUser.email,
          })}
        </span>
      </div>
    );
  }

  if (storeEvent.type === 'DISPLAY_NAME_UPDATED') {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>
          {i18n._('Display name updated')}{' '}
          {i18n._('from "{previousValue}" to "{nextValue}" by {email}', {
            previousValue: storeEvent.oldValue,
            nextValue: storeEvent.newValue,
            email: storeEvent.authUser.email,
          })}
        </span>
      </div>
    );
  }

  if (storeEvent.type === 'SHOPPER_STATEMENT_UPDATED') {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>
          {i18n._('Shopper statement updated')}{' '}
          {i18n._('from "{previousValue}" to "{nextValue}" by {email}', {
            previousValue: storeEvent.oldValue,
            nextValue: storeEvent.newValue,
            email: storeEvent.authUser.email,
          })}
        </span>
      </div>
    );
  }

  if (storeEvent.type === 'BUSINESS_PHONE_UPDATED') {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>
          {i18n._('Business phone updated')}{' '}
          {i18n._('from "{previousValue}" to "{nextValue}" by {email}', {
            previousValue: storeEvent.oldValue,
            nextValue: storeEvent.newValue,
            email: storeEvent.authUser.email,
          })}
        </span>
      </div>
    );
  }

  if (storeEvent.type === 'SETTLEMENT_DELAY_UPDATED') {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>
          {i18n._('Custom Settlement Delay updated')}{' '}
          {storeEvent.authUser
            ? i18n._('from {previousValue} to {nextValue} by {email}', {
                previousValue: storeEvent.previousValue,
                nextValue: storeEvent.nextValue,
                email: storeEvent.authUser.email,
              })
            : i18n._('from {previousValue} to {nextValue}', {
                previousValue: storeEvent.previousValue,
                nextValue: storeEvent.nextValue,
              })}
        </span>
      </div>
    );
  }
  if (storeEvent.type === 'TRANSFER_INSTRUMENT_UPDATED') {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>{i18n._('Bank Account Number updated')}</span>
        <span>
          {' '}
          {storeEvent.oldTransferInstrument?.bankAccountLast4 &&
            storeEvent.newTransferInstrument?.bankAccountLast4 && (
              <>
                {i18n._('from ')}
                {`*${storeEvent.oldTransferInstrument.bankAccountLast4} `}
                {i18n._('to ')}
                {`*${storeEvent.newTransferInstrument.bankAccountLast4} `}
              </>
            )}
        </span>
      </div>
    );
  }
  if (storeEvent.type === 'BUSINESS_LINES_UPDATED') {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>
          {i18n._('New Business Line{count, plural, one {} other {s}} associated ', {
            count: storeEvent.newBusinessLineIds.length,
          })}
        </span>
        <span>
          {' '}
          {storeEvent.newBusinessLineIds.length > 0 &&
            `${storeEvent.newBusinessLineIds.join(', ')}`}
        </span>
      </div>
    );
  }

  const translations: {
    [Key in Exclude<
      StoreEventType,
      | 'TRANSACTION_FEE_GROUP_UPDATED'
      | 'SETTLEMENT_DELAY_UPDATED'
      | 'TRANSFER_INSTRUMENT_UPDATED'
      | 'ADDRESS_UPDATED'
      | 'DISPLAY_NAME_UPDATED'
      | 'SHOPPER_STATEMENT_UPDATED'
      | 'BUSINESS_PHONE_UPDATED'
      | 'BUSINESS_LINES_UPDATED'
    >]: string;
  } = {
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    CREATED: i18n._('Created'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    CLOSED: i18n._('Closed'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    ACTIVATED: i18n._('Activated'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    DEACTIVATED: i18n._('Deactivated'),
  };
  return <div className="text-gray-700 md:col-span-1">{translations[storeEvent.type]}</div>;
};

type Props = {
  store: StoreDetailsOutput;
  transactionFeeGroupListPath?: string;
};

export const StoreEventsList = ({store, transactionFeeGroupListPath}: Props) => {
  const {i18n} = useLingui();
  return (
    <ul>
      {store.storeEvents.map((event, i) => (
        <li key={`event-${i}`} className="flex text-sm leading-6 ">
          <div className="flex flex-col items-center mr-5">
            <div className="mt-[2px] mb-[5px] h-[20px] w-[20px] rounded-full border-solid border-[2px] bg-white border-gray-200 shadow-sm" />
            {i < store.storeEvents.length - 1 && (
              <div className="flex-1 w-[2px] bg-gray-200 mb-[3px]" />
            )}
          </div>
          <div className="grid w-full pb-5 md:grid-cols-[180px_minmax(0,_1fr)] gap-x-2">
            <div className="flex md:col-span-1">
              <div className="font-medium text-gray-900 whitespace-nowrap">
                {formatLocaleDate(event.createdAt)}
              </div>
            </div>
            {renderStoreEventType({
              i18n,
              storeEvent: event,
              transactionFeeGroupListPath,
            })}
          </div>
        </li>
      ))}
    </ul>
  );
};
